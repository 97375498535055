var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "question_feedback" }, [
    _c("div", { staticClass: "form-container" }, [
      _c("div", { staticClass: "question-wrap" }, [
        _c("h3", { staticClass: "label" }, [_vm._v("问题描述")]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.question_input,
              expression: "question_input",
            },
          ],
          staticClass: "question-inp info",
          attrs: {
            name: "question-inp",
            id: "question-inp",
            placeholder: "在这里描述界面问题",
          },
          domProps: { value: _vm.question_input },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.question_input = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "photo-upload-block" }, [
        _c("h3", { staticClass: "label" }, [_vm._v("上传图片")]),
        _c("div", { staticClass: "upload-wrap clear" }, [
          _c(
            "ul",
            { staticClass: "img-list clear" },
            [
              _vm._l(_vm.imgList, function (item, index) {
                return _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.imgList.length,
                        expression: "imgList.length",
                      },
                    ],
                    key: index,
                    staticClass: "fl",
                  },
                  [
                    _c("img", {
                      staticClass: "img-responsive",
                      attrs: { src: item },
                    }),
                    _c(
                      "i",
                      {
                        staticClass: "icon-del del-btn",
                        on: {
                          click: function ($event) {
                            return _vm.delImg(index)
                          },
                        },
                      },
                      [_vm._v("x")]
                    ),
                  ]
                )
              }),
              _c("div", { staticClass: "upload-btn fl" }, [
                _c("input", {
                  attrs: {
                    type: "file",
                    accept: "image/jpeg,image/png,image/gif",
                    name: "upload_img",
                    id: "upload_img",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.uploadImg($event)
                    },
                  },
                }),
                _c("img", {
                  staticClass: "img-responsive",
                  attrs: { src: require("@/assets/ic_post_comera@3x.png") },
                }),
              ]),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "btn-bottom bg-green flex-center",
          on: { click: _vm.submit },
        },
        [_vm._v("提交")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }