export default async (url = "", data, type = "POST", method = "fetch") => {
  type = type.toUpperCase();

  if (type == "GET") {
    let dataStr = ""; //数据拼接字符串
    Object.keys(data).forEach(key => {
      dataStr += key + "=" + data[key] + "&";
    });

    if (dataStr !== "") {
      dataStr = dataStr.substr(0, dataStr.lastIndexOf("&"));
      url = url + "?" + dataStr;
    }
  }

  if (window.fetch && method == "fetch") {
    let requestConfig = {};
    if (type === "POST") {
      requestConfig = {
        method: "POST",
        body: data
      };
    } else if (type === "GET") {
      requestConfig = {
        // credentials: 'include',
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=utf-8"
        },
        mode: "cors",
        cache: "force-cache"
      };
    }

    try {
      const response = await fetch(url, requestConfig);
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      throw new Error(error);
    }
  } else {
    return new Promise((resolve, reject) => {
      let requestObj;
      if (window.XMLHttpRequest) {
        requestObj = new XMLHttpRequest();
      } else {
        requestObj = new ActiveXObject();
      }

      requestObj.open(type, url, true);
      requestObj.send(data);

      requestObj.onreadystatechange = () => {
        if (requestObj.readyState == 4) {
          if (requestObj.status == 200) {
            let obj = requestObj.response;
            if (typeof obj !== "object") {
              obj = JSON.parse(obj);
            }
            resolve(obj);
          } else {
            reject(requestObj);
          }
        }
      };
    });
  }
};
