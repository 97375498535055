<template>
  <div class="question_feedback">
    <!-- 页面头部 end -->
    <div class="form-container">
      <div class="question-wrap">
        <h3 class="label">问题描述</h3>
        <textarea
          name="question-inp"
          id="question-inp"
          class="question-inp info"
          placeholder="在这里描述界面问题"
          v-model="question_input"
        ></textarea>
      </div>
      <div class="photo-upload-block">
        <h3 class="label">上传图片</h3>
        <div class="upload-wrap clear">
          <ul class="img-list clear">
            <li v-show="imgList.length" class="fl" v-for="(item, index) in imgList" :key="index">
              <img class="img-responsive" :src="item">
              <i class="icon-del del-btn" @click="delImg(index)">x</i>
            </li>
            <div class="upload-btn fl">
              <input type="file" accept="image/jpeg,image/png,image/gif" name="upload_img" id="upload_img" @change="uploadImg($event)">
              <img src="~@/assets/ic_post_comera@3x.png" class="img-responsive">
            </div>
          </ul>
        </div>
      </div>
      <!-- 底部的按钮区 start -->
      <div @click="submit" class="btn-bottom bg-green flex-center">提交</div>
      <!-- 底部的按钮区 end -->
    </div>
  </div>
</template>

<script>
import MobileDetect from "@/static/js/mobile-detect.min.js";
import request from "@/utils/commonRequest";
import {getAliOssConfigInfo} from "@/services/my";

// const OSS = require('ali-oss');

const createTracup = data => request("http://www.tracup.com/apiv1/issue/create", data);

//判断数组中是否包含某字符串
Array.prototype.contains = function(needle) {
  for (let i in this) {
    if (this[i].indexOf(needle) > 0) return i;
  }
  return -1;
};

function signature() {
  var date = new Date();
  var y = date.getFullYear();
  var M = date.getMonth() + 1;
  var d = date.getDate();
  var h = date.getHours();
  var m = date.getMinutes();
  var s = date.getSeconds();
  return y + '-' + paddingZero(M) + '/' + y + paddingZero(M) + paddingZero(d) + paddingZero(h) + paddingZero(m) + paddingZero(s);
}

function paddingZero(num) {
  return num >= 10 ? num : '0' + num;
}

var page_path = ""; // 页面路径

export default {
  name: "question_feedback",
  data() {
    return {
      title: "", // 标题
      type: "问题反馈", // 问题类型
      question_input: "", // 界面问题描述
      imgList: [], // 问题描述的图片
      page_path: "", // 页面路径
      md: null, // 设备信息
      os: "", // 设备系统版本
      model: "" // 设备型号
    };
  },
  created() {
    window.showImg = this.showImg;
  },
  mounted() {
    let self = this;
    var device_type = navigator.userAgent; //获取userAgent信息
    self.md = new MobileDetect(device_type); //初始化mobile-detect
    self.os = self.md.os(); //获取系统
    if (self.os == "iOS") {
      //ios系统的处理
      self.os = self.md.os() + self.md.version("iPhone");
      self.model = self.md.mobile();
    } else if (self.os == "AndroidOS") {
      //Android系统的处理
      self.os = self.md.os() + self.md.version("Android");
      var sss = device_type.split(";");
      var i = sss.contains("Build/");
      if (i > -1) {
        self.model = sss[i].substring(0, sss[i].indexOf("Build/"));
      }
    }
    // console.log(self.os + "---" + self.model);//打印系统版本和手机型号
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.page_path = from.path;
    });
  },
  methods: {
    // 原生上传图片
    nativeUploadImg() {
      this.$native.nativeUploadImg(1, 1, 0);
    },
    // 上传图片成功后，APP 调用的方法
    showImg(img_url) {
      console.log(img_url);
      this.imgList.push(img_url[0]);
      console.log(img_url);
      this.imgIds.push(this.imgId);
      this.imgId++;
    },
    async uploadImg(e) {
      console.log("这里是上传图片的接口处");
      const _this = this;
      if (this.imgList.length >= 2) {
        this.$dialog.toast({
          mes: "最多上传2张图片",
          timeout: 1500,
          icon: "error"
        });
        return;
      }
      try {
        this.$dialog.loading.open('上传中...');
        const res = await getAliOssConfigInfo();
        if (res.code === 0) {
          const data = res.data.data;
          const client = new OSS({
            region: data.region,
            accessKeyId: data.access_key_id,
            accessKeySecret: data.access_key_secret,
            bucket: data.bucket, //要存储的目录名
            stsToken: data.sts_token,
            secure: true
          });
          _this.percentage = 0;
          const file = e.target.files[0]
          if (file) {
            let sign = signature();
            let name = `Uploads/image/${sign}/${file.name}`
            // 上传
            try {
              let result = await client.put(name, file);
              console.log(result.url);
              var url = result.res.requestUrls[0];
              var length = url.lastIndexOf('?');
              var imgUrl = '';
              if (length !== -1) {
                  imgUrl = url.substr(0,length);
              } else {
                  imgUrl = result.url;
              }
              e.target.value = ""; // 上传成功设置为空，不然上传不了两张相同的图片
              this.imgList.push(imgUrl);
              this.$dialog.loading.close();
              this.$dialog.toast({
                mes: '成功',
                icon: 'success',
                timeout: 1500
              });
            } catch (e) {
              console.log(e);
            }
          }
        } else {
          if (process.env.NODE_ENV === "development") {

          }
        }
      } catch (e) {
        this.$dialog.loading.close();
        console.log(e);
      }
    },
    delImg(index) {
      this.imgList.splice(index, 1);
    },
    async submit() {
      let data = {
        question_input: this.question_input,
        imgList: this.imgList
      };
      if (!data.question_input) {
        this.$dialog.toast({
          mes: "请输入界面问题描述",
          timeout: 1500,
          icon: "error"
        });
        return;
      }
      let html =
        "页面路径\n\t" +
        this.page_path +
        "\n\t------------------" +
        "\n\t附件地址\n\t";
      for (let i = 0, len = this.imgList.length; i < len; i++) {
        html += "![test_img" + i + "](" + this.imgList[i] + ")     ";
      }
      html +=
        "\n\t------------------\n\t" +
        "自定义数据\n\t" +
        this.question_input +
        "\n\t------------------\n\t手机基本信息\n\t" +
        "手机系统版本号：" +
        this.os +
        "\n\t" +
        "手机型号：" +
        this.model;
      const form_data = new FormData();
      form_data.append("uKey", "2620c0fa497c0f9c27ef4372b9d1d56f");
      form_data.append("_api_key", "c8f11ba1fa30e8e6b125ec0a3b841933");
      form_data.append("pKey", "4c8b64b52fc4a18b8d880339aa2a0b95");
      form_data.append("issueTitle", this.question_input);
      form_data.append("issueDescription", html);
      form_data.append("issueType", this.type);
      try {
        const res = await createTracup(form_data);
        if (res.code === 0) {
          this.$dialog.toast({
            mes: '提交成功',
            icon: 'success',
            timeout: 1500
          });
          this.$router.go(-1);
        } else {
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.clear:after {
  content: "";
  display: block;
  clear: both;
}

.clear {
  zoom: 1;
}

.fl {
  float: left;
}

.opacity0 {
  opacity: 0;
}

.img-responsive {
  width: 100%;
  height: 100%;
}

.form-container {
  width: 100%;
  padding-top: 0.3rem;
  box-sizing: border-box;
}

.form-container .form-item {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  width: 100%;
}

.form-container .form-item .label {
  width: 18%;
  margin-left: 0.3rem;
  margin-right: 0.2rem;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  letter-spacing: -1px;
}

.form-container .form-item .info {
  flex: 1;
  /* padding: .10rem .20rem; */
  font-size: 0.32rem;
  color: #000;
  line-height: 0.44rem;
}

.form-container .question-wrap {
  background: #fff;
}

.form-container .question-wrap .label {
  width: 100%;
  padding: 0.28rem 0.3rem;
  box-sizing: border-box;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  letter-spacing: -1px;
}

.form-container .question-wrap .info {
  font-size: 0.32rem;
  color: #000;
  line-height: 0.44rem;
}

.form-container .question-wrap textarea {
  width: 100%;
  height: 3.2rem;
  padding: 0 0.3rem 0.28rem;
  border: none;
  box-sizing: border-box;
}

.form-container .question-wrap textarea::-webkit-input-placeholder {
  color: #ccc;
}

.photo-upload-block {
  width: 100%;
  padding-left: 0.3rem;
  border-top: 0.1rem solid #f8f8f8;
  background: #fff;
  box-sizing: border-box;
}

.photo-upload-block .label {
  width: 100%;
  padding: 0.2rem 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
}

.photo-upload-block .upload-wrap {
  width: 100%;
  padding: 0.32rem 0.12rem 0.12rem 0;
  box-sizing: border-box;
}

.photo-upload-block .upload-wrap .img-list {
  width: 100%;
}

.photo-upload-block .upload-wrap .img-list li {
  position: relative;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  width: 1.28rem;
  height: 1.28rem;
}

.photo-upload-block .upload-wrap .img-list .icon-del {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -0.18rem;
  display: inline-block;
  width: 0.36rem;
  height: 0.36rem;
  font-size: 14px;
  color: #fff;
  line-height: 0.36rem;
  text-align: center;
  border-radius: 0.18rem;
  background: red;
}

.photo-upload-block .upload-wrap .upload-btn {
  position: relative;
  margin-bottom: 0.2rem;
  width: 1.28rem;
  height: 1.28rem;
  border-radius: 4px;
}

#upload_img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

/* 底部按钮 */
.btn-bottom {
  margin: 1rem auto 0;
  width: calc(100% - 0.6rem);
  height: 0.98rem;
  border-radius: 8px;
  font-size: 0.36rem;
  color: #fff;
  line-height: 0.52rem;
  background: #417df2;
}
</style>
